import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import {HashRouter} from 'react-router-dom';
import './assets/base.scss';
import Main from './Main';
import configureStore from './config/configureStore';
import {Provider} from 'react-redux';
import * as io from 'socket.io-client';
window.io = io

const store = configureStore();
const rootElement = document.getElementById('root');

// global.global_id = localStorage.getItem('user');
// let items = [{id:'123123123123',email:'wan@mail.com'},{id:'2941cfac260d4ab9be394e5050f53088',email:'mohd@mail.com'},{id:'2dd90868ba2f4fb7a2d2c04936bc294c',email:'iqbal@mail.com'},
//     {id:'c18aad8ce72240df93a68a3eec318682',email:'aideed@mail.com'}]
// var item = items[Math.floor(Math.random() * items.length)];

global.global_id = localStorage.getItem('user');
global.name = localStorage.getItem('name');
global.searchKey = '';
global.picture = localStorage.getItem('picture');

if (window.location.hostname !== "localhost") {
    global.ipUM = window.location.protocol + "//" + window.location.hostname + "/esscom-um/";
    // global.ipMsg = "http://192.168.5.236:1316/";
    global.ipMsg = window.location.protocol + "//" + window.location.hostname + ":1315/";
    // global.ipMsg = window.location.protocol + "//" + window.location.hostname + "/esscom-msg";
    global.ip_dossier = window.location.protocol + "//" + window.location.hostname + "/esscom-dossier/";
    global.ipReport = window.location.protocol + "//" + window.location.hostname + "/esscom-report/";
    global.ipCop = window.location.protocol + "//" + window.location.hostname + "/esscom-cop/";

} else {
    global.ipUM = 'https://myhardrockbit.asuscomm.com/esscom-um/';   /*Logic*/
    global.ipMsg = 'https://myhardrockbit.asuscomm.com:1315/';   /*Logic*/

    global.ip_dossier = 'https://myhardrockbit.asuscomm.com/esscom-dossier/';
    global.ipReport = 'https://myhardrockbit.asuscomm.com/esscom-report/';
    global.ipCop = 'https://myhardrockbit.asuscomm.com/esscom-cop/';

}

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component/>
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./Main', () => {
        const NextApp = require('./Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();


// export { default as Submenu } from './submenu';
