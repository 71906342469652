import {BrowserRouter as Router, Route, Redirect, Switch, Link} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';
import {Alert, Button, Col, Form, FormGroup, Input, Row} from "reactstrap";


const Dashboard = lazy(() => import('../../www/Dashboard'));
const Auth = lazy(() => import('../../www/Auth'));

const Humint = lazy(() => import('../../www/Humint'));
const Messenger = lazy(() => import('../../www/Messenger'));
const Surveillance = lazy(() => import('../../www/Surveillance'));
const Dossier = lazy(() => import('../../www/Dossier'));
const Commint = lazy(() => import('../../www/Commint'));
const Elint = lazy(() => import('../../www/Elint'));
const Analysis = lazy(() => import('../../www/Analysis'));
const COP = lazy(() => import('../../www/COP'));
const Search = lazy(() => import('../../www/Search'));
const Setting = lazy(() => import('../../www/Setting'));
const User = lazy(() => import('../../www/User'));

export const fakeAuth = {
    isAuthenticated: localStorage.getItem('user'),
    authenticate(cb) {
        localStorage.setItem('user', global.global_id)
        localStorage.setItem('role', global.role)
        localStorage.setItem('position', global.position)
        localStorage.setItem('name', global.name)
        localStorage.setItem('picture', global.picture)
        this.isAuthenticated = localStorage.getItem('user')
    },
    signout(cb) {
        localStorage.clear()
        this.isAuthenticated = null
    }
};

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        fakeAuth.isAuthenticated !== null
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
            }}/>
    )}/>
);

export const Page404 = ({location}) => (
    <div className="h-100 bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
                <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="h5 modal-title text-center">
                                <div className="mt-2">
                                    <h2 style={{fontFamily: 'fantasy'}}>404</h2>
                                    <h6>Mohon maaf, halaman yang Anda cari tidak ditemukan.
                                        Kemungkinan halaman dihapus atau Anda salah menuliskan URL.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer clearfix">
                            <div className="float-left">
                                <span>Silakan kembali ke Login</span>
                            </div>
                            <div className="float-right">
                                <Link className="mr-2 btn-icon btn-icon-only btn btn-primary" to='/login'><i
                                    className='lnr-home'></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    </div>
);
const AppMain = () => {

    return (
        <Fragment>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>

                    </div>
                </div>
            }>
                <Switch>
                    <Route exact path="/login" component={Auth}/>
                    <Route exact path="/onTable/:type/:id" component={Auth}/>
                    <Route exact path="/onMap/:type/:id" component={Auth}/>
                    <Route exact path="/AMS/:id" component={Auth}/>

                    <Route exact path="/forgot-password" component={Auth}/>

                    <Route exact path="/reset-password/:token" component={Auth}/>

                    <PrivateRoute path="/dashboard" component={Dashboard}/>
                    {
                        localStorage.getItem('role') === 'Admin' ?
                            <PrivateRoute path="/users" component={User}/> :
                            null
                    }
                    <PrivateRoute path="/messenger" component={Messenger}/>
                    <PrivateRoute path="/surveillance" component={Surveillance}/>
                    <PrivateRoute path="/cop" component={COP}/>
                    <PrivateRoute path="/search" component={Search}/>
                    <PrivateRoute path="/humint" component={Humint}/>
                    <PrivateRoute path="/dossier" component={Dossier}/>
                    <PrivateRoute path="/commint" component={Commint}/>
                    <PrivateRoute path="/elint" component={Elint}/>
                    <PrivateRoute path="/analysis" component={Analysis}/>
                    <PrivateRoute path="/setting" component={Setting}/>

                    <Route component={Page404}/>
                </Switch>

            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/login"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
